<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Date Start -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportCashAdvance.singular.dateStart')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-start"
                :config="dateFormat"
                v-model="form.dateStart"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Date End -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportCashAdvance.singular.dateEnd')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-end"
                :config="dateFormat"
                v-model="form.dateEnd"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Employee -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportCashAdvance.singular.employee')"
              label-for="employee-id"
            >
              <v-select
                id="employee-id"
                class="select-size-sm"
                v-model="form.employeeId"
                :options="LOV.employees"
                :reduce="field => field.id"
                :getOptionLabel="option => `${option.code} - ${option.pic_name}`"
              />
            </b-form-group>
          </b-col>

          <!-- Tags -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.tags')"
              label-for="tags"
            >
              <v-select
                v-model="form.tags"
                id="tags"
                label="name"
                class="select-size-sm"
                taggable
                multiple
                :options="LOV.tags"
                :reduce="field => field.id"
                :create-option="field => ({id: Date.now(), name: field})"
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="2">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
          <b-card-sub-title>
            <div>{{ $t('apps.reportCashAdvance.singular.cashAdvanceReport') }}</div>
            <div class="mt-sm-1">{{ periodReportText }}</div>
          </b-card-sub-title>
        </b-card-body>

        <b-table
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :filter="searchQuery"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :busy="isBusy"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >

          <template #table-busy>
            <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
              <div class="mb-1 text">
                <b-spinner class="align-middle"/>
              </div>
              <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
            </div>
          </template>

        </b-table>

    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItemButton,
  BForm,
  BFormGroup,
  BRow,
  BSpinner,
  BTable
} from 'bootstrap-vue'

import { endOfMonth, format, startOfMonth, parse } from 'date-fns'
import { onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BSpinner,
    vSelect,
    flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const form = ref({
      dateStart: format(startOfMonth(new Date()), 'dd/MM/yyyy'),
      dateEnd: format(endOfMonth(new Date()), 'dd/MM/yyyy'),
      employeeId: '',
      tags: []
    })

    const periodReportText = ref()

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    const dateFormat = ref({ dateFormat: 'd/m/Y' })

    const LOV = ref({
      employees: [],
      tags: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Code' },
      { key: 'employee_name', label: 'Employee' },
      { key: 'amount', label: 'Amount' },
      { key: 'realisation_amount', label: 'Realisation Amount' },
      { key: 'refund_amount', label: 'Refund Amount' },
      { key: 'balance_amount', label: 'Balance Amount' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'employee'
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })

    const getEmployees = async () => {
      LOV.value.employees = await get({ url: 'value/contact/employee'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    onMounted(async () => {
      await getEmployees()
      await getTags()
      const params = [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `employeeId=${form.value.employeeId ?? ''}`,
        `tags={${form.value.tags}}`
      ]
      const url = `reports/cash-advance?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
    })

    return {
      form,
      formats,
      LOV,
      dateFormat,
      periodReportText,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      updatePeriodText
    }
  },
  methods: {
    getReport () {
      const params = [
        `dateStart=${this.form.dateStart}`,
        `dateEnd=${this.form.dateEnd}`,
        `employeeId=${this.form.employeeId ?? ''}`,
        `tags={${this.form.tags}}`
      ]
      const url = `reports/cash-advance?${ params.join('&') }`
      this.fetchLists(url)
      this.updatePeriodText()
    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Cash Advance Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `Cash Advance Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
